import { environment } from '../../../environments/environment';

const version = 'v1';
const baseUrl = `${environment.baseUrl}/v1`;
const baseMasterUrl = `${environment.baseUrl}/master/apis/${version}`;
const baseUserUrl = `${environment.baseUrl}/user/apis/${version}`;
const baseTransactionUrl = `${environment.baseUrl}/transaction/apis/${version}`;

export const TYPE_REPORT = {
    insuranceproductReport: 1,
    productReport: 2
}

// Base URL
export const LOGIN_API = `${baseUserUrl}/login`;
export const SIDEBAR_MENU_API = `${baseUserUrl}/user/menu-item`;

// MASTER
export const SUB_AGENT_TYPE_API = `${baseMasterUrl}/sub-agent-type`;
export const BOND_TYPE_API = `${baseMasterUrl}/bond-type`;
export const WORK_TYPE_API = `${baseMasterUrl}/work-type`;
export const FAQ_API = `${baseMasterUrl}/faq`;
export const FAQ_INSURANCE_API = `${baseMasterUrl}/faq-insurance`;

// DASHBOARD
export const DASHBOARD_API = `${baseUserUrl}/dashboard`;
export const DASHBOARD_ENTITY_API = `${DASHBOARD_API}/entity-summary`;

// FILE AND DOCUMENT
export const DOCUMENT_API = `${baseUserUrl}/document/upload`;
export const DOCUMENT_DOWNLOAD_API = `${baseUserUrl}/document/download`;

// Base User URL
export const ADD_EDIT_PRINCIPAL_API = `${baseUserUrl}/principal`;
export const DETAIL_PRINCIPAL_API = `${baseUserUrl}/principal`;
export const LIST_PRINCIPAL_API = `${baseUserUrl}/principal/list`;
export const LIST_MARKETING_API = `${baseUserUrl}/principal/list-marketing`;
export const CHECK_NPWP_API = `${baseUserUrl}/principal/check-npwp`;
export const GET_ALL_PRINCIPAL_BY_SUBAGENT_API = `${baseUserUrl}/principal/all`;
export const GET_ALL_PRINCIPAL_BY_SUBAGENT = `${baseUserUrl}/principal/all`;
export const INSURANCE_API = `${baseUserUrl}/insurance`;
export const GET_ALL_INSURANCE_BY_SUBAGENT_API = `${baseUserUrl}/insurance/all`;
export const INSURANCE_BRANCH_API = `${INSURANCE_API}/branch/list`;
export const SUB_AGENT_API = `${baseUserUrl}/sub-agent`;
export const USER_MANAGEMENT_LIST_API = `${baseUserUrl}/user`;
export const FORGOT_PASSWORD_API = `${baseUserUrl}/forgot-password`;
export const CHECK_TOKEN_API = `${baseUserUrl}/check-token`;
export const CHANGE_PASSWORD_API = `${baseUserUrl}/change-password`;

// Base Transaction URL
export const CERTIFICATE_LIST_API = `${baseTransactionUrl}/certificate/list`;
export const CERTIFICATE_DETAIL_API = `${baseTransactionUrl}/certificate`;
export const GET_BOND_TYPE_DOCUMENT_API = `${baseTransactionUrl}/certificate/bond-type-document/template`;
export const ADD_EDIT_CERTIFICATE = `${baseTransactionUrl}/certificate`;
export const REVISION_CERTIFICATE = `${baseTransactionUrl}/certificate/revision`;
export const REQUEST_INFORCE_API = `${baseTransactionUrl}/certificate/inforce-request`;
export const SUBMIT_INFORCE_API = `${baseTransactionUrl}/certificate/inforce/approve-reject`;
export const DOWNLOAD_CERTIFICATE_API = `${baseTransactionUrl}/certificate/download`;
export const BULK_CERTIFICATE_API = `${baseTransactionUrl}/certificate/bulk`;
export const DOWNLOAD_BULK_CERTIFICATE_API = `${baseTransactionUrl}/certificate/download/bulk-template`;
export const TODO_LIST_CERTIFICATE = `${baseTransactionUrl}/certificate/todo`;
export const AUDIT_RAIL_API = `${baseTransactionUrl}/audit-trail/data`
export const BLANKO_API = `${baseTransactionUrl}/blanko`;
export const AVAILABLE_BLANKO_INSURANCE_API = `${BLANKO_API}/available`;
export const BLANKO_RECAP_API = `${BLANKO_API}/rekap`;
export const REPORT_LIST_API = `${baseTransactionUrl}/report/financial-insurance/list`;
export const REPORT_DETAIL_API = `${baseTransactionUrl}/report/financial-insurance`;
export const EXPORT_REPORT_API = `${baseTransactionUrl}/report/financial-insurance/export`;
